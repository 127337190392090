const utils = {
    getFormattedDate: function (inputDate) {
        var mm = inputDate.getMonth() + 1;
        var dd = inputDate.getDate();
        var yy = inputDate.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        return (dd + '-' + mm + '-' + yy);
    }, getOldDate: function () {
        const oldDate = new Date();
        oldDate.setDate(oldDate.getDate() - 15);
        var mm = oldDate.getMonth() + 1;
        var dd = oldDate.getDate();
        var yy = oldDate.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        return (dd + '-' + mm + '-' + yy);
    }, getCurrentDate: function () {
        const currentDate = new Date();
        var mm = currentDate.getMonth() + 1;
        var dd = currentDate.getDate();
        var yy = currentDate.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        return (dd + '-' + mm + '-' + yy);
    }, getAPIFormattedDate: function (inputDate) {
        const fullDate = inputDate.split("-");
        var dd = fullDate[0];
        var mm = fullDate[1];
        var yy = fullDate[2];

        return (mm + '/' + dd + '/' + yy);
    }, getAPIFormattedDateWithDateFormat: function (inputDate) {
        inputDate = new Date(inputDate);
        var mm = inputDate.getMonth() + 1;
        var dd = inputDate.getDate();
        var yy = inputDate.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = (new Date(new Date(mm + '/' + dd + '/' + yy) - tzoffset)).toISOString().slice(0, -1);

        return localISOTime;
    },
    async getAllCoverages() {
        const response = await fetch('Coverage/GetAllCoverages').catch(error => {
            console.error(`An error has occured: ${error.message}`);
            window.location.href = '';
        });

        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            if (response.status == 404) {
                this.props.history.push("/404");
            }

            if (response.status == 500 || response.status == 400) {
                this.props.history.push("/500");
            }
        }
        const data = await response.json();
        return data.coverages;
    },
    async getAllFoundries() {
        const response = await fetch('Foundry/GetAllFoundries').catch(error => {
            console.error(`An error has occured: ${error.message}`);
            window.location.href = '';
        });

        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            if (response.status == 404) {
                this.props.history.push("/404");
            }

            if (response.status == 500 || response.status == 400) {
                this.props.history.push("/500");
            }
        }
        const data = await response.json();
        return data.foundries;
    },
    async getAllRetakeReasons() {
        const response = await fetch('MasterData/GetAllRetakeReasons').catch(error => {
            console.error(`An error has occured: ${error.message}`);
            window.location.href = '';
        });

        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            if (response.status == 404) {
                this.props.history.push("/404");
            }

            if (response.status == 500 || response.status == 400) {
                this.props.history.push("/500");
            }
        }
        const data = await response.json();
        return data.allRetakeReasons;
    },
    async getAPICall(requestUrl) {
        if (requestUrl == '' || requestUrl === undefined) return null;

        const response = await fetch(requestUrl).catch(error => {
            console.error(`An error has occured: ${error.message}`);
            window.location.href = '';
        });

        if (!response.ok) {
            console.error(`An error has occured: ${response.statusText}`);
            if (response.status == 404) {
                this.props.history.push("/404");
            }

            if (response.status !== 404 && response.status >= 400) {
                this.props.history.push("/500");
            }
        }

        return await response.json();
    },
    async postAPICall(requestUrl, requestParam) {
        if (requestUrl == '' || requestUrl === undefined) return null;
        if (requestParam === null || requestParam === undefined) requestParam = '';

        const response = await fetch(requestUrl, {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestParam)
        }).catch(error => {
            console.error(`An error has occured: ${error.message}`);
            window.location.href = '';
        });

        if (!response.ok) {
            console.error(`An error has occured: ${response.statusText}`);
            if (response.status == 404) {
                this.props.history.push("/404");
            }

            if (response.status !== 404 && response.status >= 400) {
                this.props.history.push("/500");
            }
        }

        return await response.json();
    },
    async deleteAPICall(requestUrl) {
        if (requestUrl == '' || requestUrl === undefined) return null;

        const response = await fetch(requestUrl, {
            method: 'delete',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        }).catch(error => {
            console.error(`An error has occured: ${error.message}`);
            window.location.href = '';
        });

        if (!response.ok) {
            console.error(`An error has occured: ${response.statusText}`);
            if (response.status == 404) {
                this.props.history.push("/404");
            }

            if (response.status !== 404 && response.status >= 400) {
                this.props.history.push("/500");
            }
        }

        return await response.json();
    },
    checkIfValueIsNullOrEmpty: function (value) {
        return value === undefined || value === null || value === "";
    }
}

export default utils;