import utils from '../../utils';

export class AuthorizeService {
    _callbacks = [];
    _nextSubscriptionId = 0;
    _user = null;
    _isAuthenticated = false;

    async isAuthenticated() {
        const user = await this.getUser();
        return !!user;
    }

    async getUser() {
        if (this._user) {
            return this._user;
        }

        const user = await this.getUserData();
        return user.userViewModel;
    }

    async signIn(userViewModel) {
        try {
            const user = await this.login(userViewModel);
            if (user.isSuccess) {
                this.updateState(user.userViewModel);
                localStorage.setItem('UserRole', user.userViewModel.RoleName);
                return this.success();
            } else {
                return this.error(user.message);
            }
        } catch (loginError) {
            console.log("Authentication error: ", loginError);
            return this.error(loginError);
        }
    }

    async signOut() {
        try {
            const logoutData = await this.logout();
            this.updateState(undefined);
            localStorage.clear();
            if (logoutData.success) {
                return this.success();
            } else {
                return this.error(logoutData.message);
            }            
        } catch (signOutError) {
            console.log("Signout error: ", signOutError);
        }
    }

    updateState(user) {
        this._user = user;
        this._isAuthenticated = !!this._user;
        this.notifySubscribers();
    }

    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    error(message) {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    success() {
        return { status: AuthenticationResultStatus.Success };
    }

    redirect() {
        return { status: AuthenticationResultStatus.Redirect };
    }

    static get instance() { return authService }

    async login(userViewModel) {
        const userData = await utils.postAPICall('Account/Login', userViewModel);
        return userData;
    }

    async getUserData() {
        const userData = await utils.getAPICall('Account/GetUserDetails');
        return userData;
    }

    async logout() {
        const logoutData = await utils.getAPICall('Account/LogOut');
        return logoutData;
    }

    async changePassword(userViewModel) {
        const userData = await utils.postAPICall('Account/ChangePassword', userViewModel);
        return userData;
    }
}


const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};
