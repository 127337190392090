import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import AuthorizeRoute from './components/authorization/AuthorizeRoute';
import './custom.css'
import './scss/style.scss';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
const TheLayout = React.lazy(() => import('./components/coreUI/TheLayout'));
const Page404 = React.lazy(() => import('./components/coreUI/Page404'));
const Page500 = React.lazy(() => import('./components/coreUI/Page500'));
const Login = React.lazy(() => import('./components/coreUI/Login'));

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <HashRouter>
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />                        
                        <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />                        
                        <AuthorizeRoute path='/' component={TheLayout} />     
                    </Switch>
                </React.Suspense>
            </HashRouter>
        );
    }
}
