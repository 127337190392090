export const logoNegative = ['608 134', `
  <title>IA Admin Assist logo</title>
   <g>
  <title>background</title>
  <rect fill="#303c54" id="canvas_background" height="136" width="610" y="-1" x="-1"/>
  <g display="none" overflow="visible" y="0" x="0" height="100%" width="100%" id="canvasGrid">
   <rect fill="url(#gridpattern)" stroke-width="0" y="0" x="0" height="100%" width="100%"/>
  </g>
 </g>
 <g>
  <title>IA Admin Assist</title>
  <text xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="24" id="svg_1" y="252" x="163" stroke-width="0" stroke="#000" fill="#000000">IA ADMIN ASSIST</text>
  <text font-weight="normal" xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="70" id="svg_2" y="86.5" x="8.5" stroke-width="0" stroke="#000" fill="#ffffff">IA ADMIN ASSIST</text>
  <ellipse rx="1" id="svg_12" cy="227.5" cx="390.5" fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff"/>
  <ellipse stroke="#000" id="svg_14" cy="56.5" cx="52.5" fill-opacity="null" stroke-opacity="null" stroke-width="0.5" fill="#fff"/>
  <line stroke-linecap="null" stroke-linejoin="null" id="svg_20" y2="151.5" x2="46.5" y1="150.5" x1="46.5" stroke-width="1.5" stroke="#ffffff" fill="none"/>
 </g>
`]
